export const initToTop = () => {
    window.addEventListener('scroll', function() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        const totopButton = document.querySelector('.totop');

        if (scrollPosition > window.innerHeight) {
            totopButton.classList.add('active');
        } else {
            totopButton.classList.remove('active');
        }
    });
    
    document.querySelector('.totop').addEventListener('click', function(e) {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
}
