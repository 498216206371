export const initScrollShow = () => {
    window.addEventListener('scroll', function() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        const scrollshowElement = document.querySelector('.scrollshow');
    
        if (scrollPosition > window.innerHeight) {
            scrollshowElement.classList.add('active');
        } else {
            scrollshowElement.classList.remove('active');
        }
    });
}
